(($)->
  'use strict'
  ###################################
  # Axios
  # @link https://en.wikipedia.org/wiki/List_of_HTTP_header_fields
  # @link https://github.com/mzabriskie/axios
  ###################################
  instance = axios.create({
    headers:
      'Content-Type': 'application/json',
      'Accept': 'application/json',
  })

  Vue.prototype.$http = instance

  new Vue({
    el: '#idiy-realtime'
    data:
      items: []
    created: ->
      endpoint = $('#idiy-realtime').data('endpoint')

      this.$http.get("#{endpoint}.json")
      .then (response)=>
          @items = response.data.items
  })
) jQuery